import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import { Fade } from "react-awesome-reveal";
import Seo from "../components/seo"

const ArticleTemplate = ({ data }) => {
  const post = data.post
  const thisEdge = data.allPosts.edges.find(edge => edge.node.id === post.id)
  const previousArticle = thisEdge.previous
  const nextArticle = thisEdge.next

  return (
    <Layout navOverlay={false} progressEnabled={true} classes="article">
      <Seo title={post.frontmatter.title} description={post.frontmatter.caption} />
      <Fade triggerOnce>
        <div className="article-header">
          <div className="container">
            <p className="date">
              <span>{post.frontmatter.date} · </span>
              {post.frontmatter.categories.map((category, i) => [
                i > 0 && ", ", category
              ])}
            </p>
            <h1>{post.frontmatter.title}</h1>
            <div className="media">
              <div className="media-body">
                <p>{post.frontmatter.author}</p>
              </div>
            </div>
          </div>
        </div>
      </Fade>
      <article className="article-content">
        <div className="container">
          <Fade triggerOnce>
            <p className="lead">{post.frontmatter.caption}</p>
            <section dangerouslySetInnerHTML={{ __html: post.html }} />
          </Fade>
        </div>
      </article>
      <Fade triggerOnce>
        <nav className="article-nav">
          <div className="container">
            <div className="row">
              <div className="col">
                {previousArticle && (
                  <Link to={previousArticle.fields.slug}>
                    <div className="media">
                      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64"><g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" transform="translate(0.5 0.5)" fill="#000" stroke="#000"><circle cx="32" cy="32" r="29" fill="none" stroke="#000" strokeMiterlimit="10"></circle><line x1="48" y1="32" x2="16" y2="32" fill="none" strokeMiterlimit="10"></line><polyline points="27 43 16 32 27 21" fill="none" strokeMiterlimit="10"></polyline></g></svg>
                      <div className="media-body">
                        <span>Previous Post</span>
                        <p>{previousArticle.frontmatter.title}</p>
                      </div>
                    </div>
                  </Link>
                )}
              </div>
              <div className="col">
                {nextArticle && (
                  <Link to={nextArticle.fields.slug}>
                    <div className="media">
                      <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64"><g strokeLinecap="round" strokeLinejoin="round" strokeWidth="1" transform="translate(0.5 0.5)" fill="#000" stroke="#000"><circle cx="32" cy="32" r="29" fill="none" stroke="#000" strokeMiterlimit="10"></circle><line x1="16" y1="32" x2="48" y2="32" fill="none" strokeMiterlimit="10"></line><polyline points="37 21 48 32 37 43" fill="none" strokeMiterlimit="10"></polyline></g></svg>
                      <div className="media-body">
                        <span>Next Post</span>
                        <p>{nextArticle.frontmatter.title}</p>
                      </div>
                    </div>
                  </Link>
                )}
              </div>
            </div>
          </div>
        </nav>
      </Fade>
    </Layout>
  );
}

export default ArticleTemplate

export const pageQuery = graphql`query NewsPostByID($id: String!) {
  post: markdownRemark(id: {eq: $id}) {
    id
    html
    frontmatter {
      date
      title
      author
      categories
      caption
    }
  }
  allPosts: allMarkdownRemark(
    sort: {order: DESC, fields: [frontmatter___date]}
    filter: {frontmatter: {template_key: {eq: "article"}}}
  ) {
    edges {
      node {
        id
      }
      next {
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
      previous {
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
    }
  }
}
`
